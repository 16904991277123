import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="2012 Mensajes" />
      <h1>2012 Mensajes:</h1>
      <p>
        Abajo están los mensajes de los domingos del 2012, en una lista ordenada
        cronológicamente. Para DESCARGAR un mensaje haga CLIC DERECHO en enlace de
        descarga, y seleccione “Salvar destino como...” (Save Target As...) o “Salvar
        enlace como...” (Save Link como...), dependiendo del navegador que use. Para
        ESCUCHAR, sólo haga clic en el enlace “escuchar”. Para mayor ayudar, para escuchar
        y descargar archivos de audio, visite nuestra Página de Ayuda para Audio.{' '}
      </p>
      <h2>2012 Mensajes</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>08/01/12</td>
            <td>Jason Henderson </td>
            <td>Escogidos En El Amado</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/01_Escogidos.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/Efesios/05Efesios_1_4-6.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>04/03/12</td>
            <td>Jason Henderson </td>
            <td>El Misterio de Su Voluntad</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/03_Misterio.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/Efesios/07Efesios_1_8-9.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>15/05/12</td>
            <td>Jason Henderson </td>
            <td>Una Casa Entre Dos Testigos</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/04_Casa_Entre_Dos_Testigos.mp3')}>
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/jasonhenderson/120515CasaEntreDosTestigos.pdf',
                  )}
                >
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>10/06/12</td>
            <td>Jason Henderson </td>
            <td>Libertad y Esclavitud 01</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/05_Libertad_Esclavitud.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/120610LibertadYEsclavitud01.pdf')}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>17/06/12</td>
            <td>Jason Henderson </td>
            <td>Libertad y Esclavitud 02</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/06_Libertad_Esclavitud_2.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/120617LibertadYEsclavitud02.pdf')}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>15/07/12</td>
            <td>Jason Henderson </td>
            <td>Hebrews 11:1 - La Fe 01</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/07_Hebrews_11_La_Fe_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/120715Hebreos11_LaFe01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/07/12</td>
            <td>Jason Henderson </td>
            <td>Hebrews 11:1 - La Fe 02</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/08_Hebreos_11_La_Fe_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/120722Hebreos11_LaFe02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>05/08/12</td>
            <td>Jason Henderson </td>
            <td>La Debilidad</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/09_La_Debilidad.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/120805LaDebilidad.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>19/08/12</td>
            <td>Jason Henderson </td>
            <td>La Fe Vence Al Mundo</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/10_Fe_Vence_Al_Mundo.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/120819FeVenceAlMundo.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>02/09/12</td>
            <td>Jason Henderson </td>
            <td>Verdadero Servicio</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/11_Verdadero_Servicio.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/120902VerdaderoServicio.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>11/11/12</td>
            <td>Jessica Henderson </td>
            <td>El Cuerpo Celestial</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/jessiehenderson/121111_Cuerpo_Celestial.pdf',
                  )}
                >
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>25/11/12</td>
            <td>Keren Alvarado</td>
            <td>Las Fiestas de Israel</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/121122FiestasDeIsrael.pdf')}>texto</a>
              </div>
            </td>
          </tr>

          <tr>
            <td>02/12/12</td>
            <td>Jason Henderson</td>
            <td>José: Un Cuadro de Cristo</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/mp3/12_Jose.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/12/pdf/121202_Jose.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
